
<template>
    <div>
        <div style="border-bottom: 1px solid #475c78;">
            <h4 class="temp_head mt-20 mb-10"><span style="color: #223957;">Templates</span></h4>
        </div>
        <div>
            <!-- <div style="">
                <button class="btn btn-success" @click="addTemplate()">Add New Template</button>
            </div> -->
            <div class="col-md-12 row mt-20"  > 
                <div class="col-md-4" v-for="item in list" :key="item.id" >
                    <whats-app-template :item="item" :key="item.id" ></whats-app-template>
               
                </div>
                
            </div>
        </div>
        <!-- <div v-if="viewno==2">
            <div class="col-md-12 row"  >
                <div class="col-md-5" >
                    <whats-app-template :edit="1"></whats-app-template>
                </div>
                
            </div>
        </div> -->
    </div>
</template>
    
<script>
import { mapGetters } from 'vuex';
import WhatsAppTemplate from './WhatsAppTemplate.vue';
export default{
    components: { WhatsAppTemplate },
    data() {
        return {
            leftDrawerOpen: false,
            users: 0,
            records: 0,
            apprecords: 0,
            editview:false,
            selectgallery:false,
            showlist:true,
            selectedgallerytemplate:null,
            // viewno:1
        };
    },
    computed: {
        ...mapGetters(['loggedinuser','list'])
    },
    mounted() {
        this.refresh();
    },
    methods: {
        
        refresh() {
            //get the wattsapp templates
            window.axios.post("api/threads/wattsapp/company/templates/approved/fetch")
                .then(response => this.processResponse(response.data))
                .catch(error => console.log(error));
        },
        processResponse(data){
            this.$store.commit('assignlist',data.list);
           
            // this.list = data.list;
        },
        // addTemplate(){
        //     // this.showlist=false
        //     this.viewno=2
        //     // this.$router.push({ path: 'templates/gallery' })
        // },
    },
}
</script>
<style scoped>
.grd-bg-info{
    background-color:#80d3f9;
}
.grd-orange{
    background:  #FF9933;
}
.bg-green, .bg-green > .small-box-footer{
    background-color:#138808;
    color:black;
}
.grd-white{
    background: white;
    color: black;
}
.grd-white-color{
    color: black;
}


.inner{
    
}
</style>
    