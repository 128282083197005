<template >
    <div>
        <div>
            <div>
                <h5><span class="ml-10">{{ this.item.wattsapptemplatename }}</span></h5>
            </div>
            <div class="temp_size">
                <div v-if="checkHeader() == 1">
                    <div>
                        <h5 class="mt-10"><span class="ml-20">Image</span></h5>
                    </div>
                    <div class="col-md-12">
                        <div class="temp_header text-center">
                            <img :src="this.item.components[ checkHeader() ]['sampleurl']" style="width: 180px;height: 180px;">
                        </div>
                    </div>

                </div>
                <div>
                    <div>
                        <h5 class="mt-10"><span class="ml-20">Content</span></h5>
                    </div>
                    <div class="col-md-12">
                        <div :class="checkHeader() == 1 ? 'temp_body' : 'new_temp_body'">
                            <!-- Body -->
                            <div class="col-md-12 mb-10 mt-10">
                                <span style="word-wrap: break-word;">{{ getjsondata() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button class="btn btn-info" style="background: #f94400;border: 1px solid #f94400;"
                    @click="select()">Select</button>
            </div>
        </div>
    </div>
</template>
<script>
// import axios from 'axios';
export default {
    // mixins:[UploadMixin2],

    props: ['item', 'gallerytemplate', 'edit'],
    data() {
        return {
            jsondata: [],
            result: false,
            templatename: '',
            body: '',
            jsonValue: [],
            newresult: [],
            samples: [],
            sample: null,
            headertype: 5,
            headertext: null
        };
    },
    computed: {

    },
    mounted() {
        let obj = { 'type': 5, 'directorypath': 'wattsapp', 'counter': 0, 'typeid': 0, 'typeint': 12 }
        this.$store.commit('assignfilecomponents', obj);
    },
    methods: {
        // selectimage(sample){

        //     this.sample = sample
        // },
        // fetchsampleimages(){
        //     axios.post('api/threads/wattsapp/samples')
        //         .then((response)=>this.processSameImageResponse(response.data))
        //         .catch((error)=>console.log(error))
        // },
        // processSameImageResponse(data){
        //     console.log({...data});
        //     this.samples = data.samples
        // },
        // uploaded(){
        //     console.log("Uploaded");
        // },  
        // cancel(){
        //     this.templatename=''
        //     this.category=''
        //     this.language=''
        //     this.body=''
        //     this.$parent.viewno=1


        // },
        // saveTemplate(){
        //     let param=null
        //     let header = null
        //     if(this.headertype>1){
        //         if(this.headertype==2){
        //             header = {}
        //             header["format"]="TEXT";
        //             header["text"]=this.headertext;
        //         } else if(this.headertype==3){
        //             header = {}
        //             header["format"]="IMAGE";
        //             header["example"] = {}
        //             header["example"]['header_handle']=[];
        //             header["example"]['header_handle'].push(this.sample.uploadedid)
        //         }
        //     }
        //     if(this.item!=null){
        //         param = { body1:this.body, templateid:this.item.id,
        //             category: this.category, language: this.language, header1:header }
        //         this.getApi(param);
        //     }else{
        //         if(this.templatename!=''){
        //             param = { body1: this.body, templatename: this.templatename,
        //                 category:this.category, language: this.language, header1:header
        //             }
        //             this.getApi(param);
        //         }else{
        //             alert("Template name cannot be blank.")
        //         }
        //     }
        // },
        // getApi(param){
        //     axios.post('api/threads/wattsapp/templates/save',param)
        //         .then((response)=>this.processResponse(response.data))
        //         .catch((error)=>console.log(error))
        // },
        // processResponse(data){
        //     this.result=data
        //     this.$parent.viewno=1
        //     this.$parent.refresh()
        // },
        getjsondata() {
            let value = null
            this.item.components.find(function (element) {
                // console.log(element)
                if (element.componenttypeint == 2) {
                    value = JSON.parse(JSON.stringify(element.componentjson))
                    
                }
            })
            // console.log("Value",value)
            return value?.text
        },
        // processApprovalResponse(data){
        //     this.newresult = data.response
        //     console.log(this.result)
        // },
        select() {
            console.log("item here")
            console.log(this.item);
            this.$store.commit('assignedititem', this.item)
            this.$store.commit('assignviewno', 2)
            this.$router.push({ name: 'broadcast' })
            // this.$router.push({ name: 'customer' })
            // this.$store.commit('assignviewno',1)

        },
        checkHeader() {
            let value = 0
            this.item.components.find((element) => {
                if (element.componenttypeint == 1) {
                    value = element.componenttypeint
                }
            })
            return value
        },
        getImage(){

        }

    },
}
</script>